import UserImplement from '../../wxsys/comps/uixContainer/UserImplement';
import Config from "../../wxsys/lib/base/config";
import configUtil from "./configUtil";
import languageList from "./language.json";
import ConfigContextProcessor from 'core/framework/ConfigContextProcessor';
import { parsePath, createPath } from 'core/appHistory';
import { cloneDeep, intersection, isArray, merge } from "lodash";
import vantIcons from '@vant/icons'
import store from 'store';
import loader from "/core/framework/loader";
import tabbar from 'core/tabbar';
import history from "core/appHistory";

export default {
    //系统相关配置
    initStateConfig: {
        loginPage: "/entry/mobileapp/mobile/user/login"
    },
    //登出
    async logout(page) {
        await UserImplement.logout(page);
        store.remove('autoLogin');
        let loginPath = parsePath(location.href);
        loginPath.hash = "#" + this.initStateConfig.loginPage;
        location.href = createPath(loginPath);
        location.reload();
    },
    //接收iframe请求数据
    getMessage(page){          
        //关闭页面，逻辑参考NavBar导航栏     
        const closePage=()=>{
             if (window.microService.isMicroService) {
                let currentPageElement = document.querySelector('#container>.current_page');
                if (currentPageElement && currentPageElement.classList.contains("single-spa")) {
                    window.history.go(-1);
                    return;
                }
            }
            page.getPage().navigateBack();
        }
        // 监听iframe发送的消息，兼容iframe页面刷新，关闭等事件
        let doMessage=(event)=>{
            if (event.data){
                let eventData = event.data;
                if(typeof eventData=="string"){
                    try {
                        eventData=JSON.parse(eventData||"{}");
                    } catch (error) {}					
                }                
                //判断消息是否发送给门户的
                if(eventData.type!="shell"){
                    return;
                }               
                // //执行动作
                switch(eventData.name){               
                    case "navigateBack":return closePage();//关闭当前页面
                    case "navigateTo":return page.navigateTo(eventData.args);                
                }
            }
        }
        //在绑定前先解绑        
        window.removeEventListener("message",doMessage);
        window.addEventListener('message',doMessage);       
    },
    /**
     * 获取登陆用户信息，用于系统登陆后首页展示使用
     * 企业门户用户：个人信息及岗位信息
     * 控制台门户用户：租户组织信息、岗位信息
     */
    async getOrgData(userInfo) {
        if (this.orgData && this.orgData.length > 0) {
            return this.orgData;
        }
        let mainOrgId = userInfo?.data7;
        this.orgData = [];
        //一般用户获取信息
        let orgsData = {};
        userInfo?.orgs.toJSON().forEach((org) => {
            if (org.type == "psm") {
                let orgDatas = org.fname?.split("/").reverse();
                orgsData[org.fid] = {
                    id: org.fid,
                    name: orgDatas[1],
                    orgId:org.orgID,
                    orgName: orgDatas.filter(item => item.endsWith(".dpt"))[0],
                    orgFid: org.fid,
                    orgFname: org.fname,
                    parentId:org.parentID
                };
            }
        });
        let orgsArr = Object.values(orgsData);
        this.orgData = this.orgData.concat(orgsArr);

        //兼容不满足上述情况的场景
        if (this.orgData.length == 0) {
            let user = {
                id: userInfo.id,
                name: userInfo.name
            }
            this.orgData.push(user);
        }
        //当前用户信息
        let currentPsm = null;
        //切换到上次选择的组织		        			
        let currentProfile = localStorage.getItem("mobile-"+userInfo.id + "-currentProfile");
        if (currentProfile) {
            currentProfile = JSON.parse(currentProfile);
            let currentPsmData = this.orgData.filter(item => item.id == currentProfile.id);
            if (currentPsmData.length > 0) {
                currentPsm = currentPsmData[0];
            }
        }        
        if (!currentPsm) {
            //如果没有相关数据优先展示主单位数据            
            this.orgData.forEach(item => {
                if (mainOrgId && item?.parentId == mainOrgId) {
                    currentPsm = item;
                }
            });
        }
        //如果都内容进行保底
        if (!currentPsm) {
            currentPsm = this.orgData[0];
        }
        this.orgData.filter(item => item.id == currentPsm.id).map(item => item.selected = true);
        //设置当前岗位
        this.onUserInfoMenuClick(currentPsm, userInfo);

        //排序，防止用户展示信息混乱
        this.orgData.sort((a, b) => a.name.localeCompare(b.name));
        window.orgData=this.orgData;
        return this.orgData;
    },
    //用户信息下拉菜单点击事件
    onUserInfoMenuClick(currentOrg, userInfo) {
        if (!currentOrg) {
            return;
        }
        //设置当前用户组织信息，以便传递至其他页面使用         
        localStorage.setItem(`mobile-${userInfo.id}-currentProfile`, JSON.stringify(currentOrg));
    },    
    //获取菜单信息
    async fetchMenu(page,anonymous=false) {
        if (!anonymous&&window.microService.menus && window.microService.menus.length > 0) {
            return window.microService.menus;
        }
        let { data } = await page.request({
            url: "/entry/manager/authorized/menus?types=mobile&context=mobile&modified=true"
        });  
        //非匿名访问设置
        if(!anonymous){            
            window.microService.menus = data;
            window.microService.isMicroService = true;
            //先清理
            window.microService.tabBars=[]
            //加载移动tab
            loader.toMenuMap(data);
            tabbar.reset();    
        }   
        return data;
    },
    //获取流程关联菜单
    async fetchProcessMenu(page) {
        if (!window.microService.menus || window.microService.menus.length == 0) {
            await this.fetchMenu(page);
        }
        return this.findMenus(window.microService.menus, item => item.types?.includes("process"));
    },
    //获取当前人员岗位信息
    getCurrentPsm(userInfo) {
        let psm= JSON.parse(localStorage.getItem("mobile-"+userInfo.id + "-currentProfile"));
        if(!psm){
            this.getOrgData(userInfo);
            return this.getCurrentPsm(userInfo);
        }
        return psm;
    },
    //获取当前用户权限菜单
    async getCurrentMenus(page, userInfo) {
        let menus = await this.fetchMenu(page);
        let currentPsm = this.getCurrentPsm(userInfo);
        let psmForgID = currentPsm.orgFid || currentPsm.id;
        //租户组织及system特殊账号
        let orgID = currentPsm.orgId || "system";
        let menuKey = psmForgID + "_" + orgID;
        this.psmMenus = this.psmMenus || {};
        this.psmMenus[menuKey] = this.psmMenus[menuKey] || this.filterMenus(cloneDeep(menus), psmForgID, orgID, userInfo);
        return this.psmMenus[menuKey];
    },
    //获取tabbar菜单
    async getTabMenu(page,anonymous){
        let menus = await this.fetchMenu(page,anonymous);
        return this.findMenus(menus,item=>item.types?.includes("tab"))
    },
    //获取默认打开tabbar菜单
    async getDefaultMenu(page,anonymous,portalConfig){
        if(portalConfig){
            const portalIndexPageObj= await this.initPortalIndexPage(page,portalConfig?.[0]?.content);
            if(portalIndexPageObj&&Object.keys(portalIndexPageObj).length>0){
                return portalIndexPageObj;
            }
        }
        let tabbarMenu=await this.getTabMenu(page,anonymous);
        if(!tabbarMenu||tabbarMenu.length==0){
            return null;
        }
        let defaultMenu=tabbarMenu.filter(menu=>{
            if(typeof menu.ext=="string"){
                menu.ext=JSON.parse(menu.ext||{});
            }
            return String(menu.ext?.tabBar?.homePage)=="true";
        });
        //设置默认页>设置菜单第一个元素>移动端app.json设置菜单
        return defaultMenu?.[0];
    },
     //初始化首页
     async initPortalIndexPage(page,portalConfig){
        const {portalIndexRole}=portalConfig;        
        if(!portalIndexRole||portalIndexRole.length==0){
            return;
        }

        const {data}=await page.request({url:"/entry/authorize/currentUserRoles"});
        let allRoles=[];
        data.forEach(item=>allRoles.push(item.code));
        let indexPageObj={};
        for(const indexInfo of portalIndexRole){
            if(!indexInfo.indexUrl||!indexInfo.roleCode){
                continue;
            }
            const roles=intersection(allRoles,indexInfo.roleCode.split(","));
            if(roles.length>0){
                indexPageObj.url=indexInfo.indexUrl;
                break;              
            }
        }    
        return indexPageObj;    
    }, 
    //根据用户组织权限过滤菜单
    filterMenus(menus, psmFid, orgId, user) {
        //过滤功能类型菜单数据
        menus=this.dfsFindClass(menus,item => item.types?.includes("func"));   
        let subjects = [];
        //个人
        if (psmFid) {
            subjects = psmFid.split("/");
            subjects = subjects.map((id) => {
                return id.split(".")[0]
            })
            subjects.length > 2 && subjects.push(subjects[subjects.length - 1] + "@" + subjects[subjects.length - 2]);
        }
        //自己
        subjects.push(user.id);
        //组织
        if (orgId) {
            subjects.push(orgId.indexOf("@") > -1 ? orgId : (user.id + "@" + orgId));
        }
        //过滤权限菜单
        if (subjects.length == 0) {
            return;
        }
        return this.filterMenusByAuth(menus, subjects);
    },
    //按照权限过滤菜单
    filterMenusByAuth(menus, subject) {
        if (typeof subject == "string") {
            subject = [subject];
        }

        let result = {
            children: menus,
            selectedKeys: []
        };
        //获取有权限的菜单
        let filterItem = (item) => {
            //区分叶子节点和目录节点
            if (item.children && item.children.length > 0) {
                item.children = item.children.filter((childItem) => {
                    return filterItem(childItem);
                })
                return true;
            } else if (item.children && item.children.length == 0) {
                return false;
            } else if (!item.subjects) {
                //特殊假设  无subjects信息代表没有进行权限管理 是全岗支持的功能
                return true;
            } else {
                return intersection(item.subjects, subject).length > 0;
            }
        }
        filterItem(result);

        //删除空父菜单
        let removeEmptyChildrenMenuChanged = true;
        while (removeEmptyChildrenMenuChanged) {
            removeEmptyChildrenMenuChanged = false;
            let filterItem = (item) => {
                if (item.children && item.children.length > 0) {
                    //如果下面子菜单内容都是不显示则父菜单也不显示					
                    let showMenus = item.children.filter(item => item.hideInMenu !== true);
                    if (showMenus.length == 0) {
                        item.hideInMenu = true;
                        return false;
                    }
                    item.children = item.children.filter((childItem) => {
                        return filterItem(childItem);
                    })
                    return true;
                } else if (item.children && item.children.length == 0) {
                    removeEmptyChildrenMenuChanged = true;
                    return false;
                } else {
                    return true;
                }
            }
            if (result.children.length == 0) {
                return;
            } else {
                filterItem(result);
            }
        }

        return result.children;
    },
    dfsFind(data, condition) {
        if (isArray(data)) {
            data = {
                children: data
            }
        }
        if (data.children && data.children.length > 0) {
            for (const item of data.children) {
                const result = this.dfsFind(item, condition);
                if (result !== undefined) {
                    return result;
                }
            }
        } else if (condition(data)) {
            return data;
        }
    },
    //按照条件递归查询菜单数据并返回集合结果数据
    findMenus(arr = [], condition) {
        let filterMenuData = [];
        filterMenuData.push(...arr.filter(item => condition(item)));
        arr.forEach(sub => {
            let filterData = this.findMenus(sub.children, condition);
            if (filterData.length > 0) {
                filterMenuData.push(...filterData);
            }
        })
        return filterMenuData;
    },
    // 递归实现DFS查找,删除不符合条件的菜单，保留树形结构
    dfsFindClass(data=[], condition) {
        for (let i = 0; i < data.length; i++) {
            let item = data[i];
            if (item.hasOwnProperty("children")) {
                item.children = this.dfsFindClass(item.children, condition);
            } else if (!condition(item)) {
                data.splice(i, 1);
                --i;
            }
        }
        return data;
    },
    //判断获取自定义图标
    async getIcon(page, iconCode) {
        if (!iconCode) {
            return;
        }
        //老样式图标
        let oldCssIcon = ["icon", "dataControl", "e-commerce", "glyphicon", "linear", "round"];
        let oldIcon = oldCssIcon.filter(item => iconCode.indexOf(`${item}-`) > -1);
        if (oldIcon.length > 0) {
            return `<span class="${iconCode}"></span>`;
        }
        let allVantIcons = wx.getStorageSync("allVantIcons");
        if (!allVantIcons) {
            let { basic, filled, outline } = vantIcons;
            allVantIcons = basic.concat(filled).concat(outline);
            wx.setStorageSync("allVantIcons", allVantIcons);
        }
        //如果是vantui内置图标直接返回
        if (iconCode&&allVantIcons.includes(iconCode)) {
            return iconCode;
        }
        //查询自定义图标
        if (!page.configIcons) {
            let configIcons = await configUtil.getIconConfig(page);
            page.configIcons = configIcons?.[0].content?.icons;
        }
        let icon = page.configIcons.filter(item => item.code == iconCode);
        if (icon && icon.length > 0) {
            return icon[0].svg;
        }
        return iconCode;
    },
    convertI18n(page, key) {
        if (!key) {
            return "";
        }
        return page.i18n(key, import.meta.url);
    },
    //获取语言列表
    getLanguage(langArr) {
        let allLanguageArr = Object.values(languageList);
        allLanguageArr.forEach(item => item.name = `${item.lang} ${item.label}`);
        if (typeof langArr == "string") {
            if (langArr.trim().length == 0) { langArr = "zh-CN"; }
            return languageList[langArr];
        }
        if (langArr instanceof Array) {
            if (langArr.length == 0) { langArr.push("zh-CN") };
            return allLanguageArr.filter(item => item.lang&&langArr.includes(item.lang));
        }
        return languageList;
    },
    //设置用户多语言
    async setCurrentLanguageUser(page, userInfo, language) {
        await ConfigContextProcessor.localStore.setItem("configContext.currentLanguageId", { currentLanguageId: language });        
        await this.setCurrentLanguage(page, userInfo);
    },
    //设置当前语言
    async setCurrentLanguage(page, userInfo) {
        try {            
            //判断是否开启多语言,登陆后刷新页面需要重新判断
            const useLanguageData = await Config.getConfigByPage(page, "entry", "use-language");
            let useLanguage = useLanguageData?.[0].content?.useLanguage;
            //设置语言相关全局配置
            window.mobileLanguage={};            
            mobileLanguage.openLanguage = page.configContextProcess.i18nEnabled || useLanguage === true;   
            //设置当前语言
            mobileLanguage.currentLanguageInfo = this.getLanguage(page.configContext.currentLanguageId||"zh-CN");
            let localUserLanguageKey = `configContext.currentLanguageId`;
            //判断是否开启多语言
            if (!mobileLanguage.openLanguage) {
                //默认中文                
                await ConfigContextProcessor.localStore.setItem(localUserLanguageKey, { currentLanguageId: "zh-CN" });
                return;
            }

            //走统一配置开启多语言        
            mobileLanguage.supportLanguages = (useLanguageData?.[0].content?.supportLanguages || ["zh-CN", "en-US", "zh-TW"]);

            // 获取当前语言            
            let localUserLanguage = await ConfigContextProcessor.localStore.getItem(localUserLanguageKey);
            let currentLanguage = localUserLanguage?.currentLanguageId;

            //语言设置优先级：语言组件切换语言>统一通用配置语言>默认语言（zh-CN）
            if(userInfo&&!currentLanguage){
                //获取用户设置当前语言
                let currentLanguageData;
                try {
                    currentLanguageData = await Config.getOrgConfig("entry", "current-language", undefined, userInfo.id);
                } catch (error) {
                    //多语言查询
                }
                currentLanguage = currentLanguageData?.[0].content?.currentLanguage || page.configContext.currentLanguageId||"zh-CN";
            }   

            //如果当前语言与系统加载语言不一致则重新设置新的语言并刷新
            if (page.configContext.currentLanguageId != currentLanguage) {
                //如果语言不一致则重新设置语言并刷新页面
                page.configContext.currentLanguageId = currentLanguage;
                //匿名语言设置
                await ConfigContextProcessor.localStore.setItem("configContext.currentLanguageId", { currentLanguageId: currentLanguage });
                top.location.reload();
            }

            //加载语言资源
            await page.configContextProcess.processConfigContext(true);

            //获取当前语言配置
            const languageConfigData = await Config.getConfigByPage(page, "entry", "language-config", currentLanguage);
            let languageConfig = languageConfigData?.[0]?.content || {};
            if(page.configContext.i18n.hasOwnProperty(currentLanguage)){
                merge(page.configContext.i18n[currentLanguage].resource, languageConfig);
            }else{
                page.configContext.i18n[currentLanguage]={languageId:currentLanguage,resource:languageConfig};
            }           

        } catch (error) {
            console.error(this.convertI18n(page, "加载语言配置异常"), error);
        }
    },
    //加载上次的访问主题    
    setCurrentThemeByLocal(page){                
        let localCurrentTheme=localStorage.getItem("mobile-currentTheme");       
        if(localCurrentTheme){              
            localCurrentTheme=JSON.parse(localCurrentTheme);
            page.configContext.currentTheme = localCurrentTheme.code; 
            if(!page.configContext.hasOwnProperty(localCurrentTheme.code)){
                page.configContext[localCurrentTheme.code] = localCurrentTheme;                 
            } 
            //同步皮肤主题色到整个移动页面上
            let {configContext}=page;
            let primaryColor=configContext[configContext.currentTheme]?.token?.primaryColor;
            if(primaryColor){
                let rootElement = document.documentElement;
                rootElement.style.setProperty('--primary-color', primaryColor);  
            }
        }       
    },
    //获取当前主题配置
    async setCurrentTheme(page,userInfo,themeCode) {        
        try {    
            if(themeCode){
                localStorage.setItem("mobile-local-currentTheme",themeCode);
            } 
            if(!page.configContext){
                page.configContext={};
            }            
            let userThemeKey = "mobile-currentTheme";
            //加载上次的访问主题            
            let localCurrentTheme = localStorage.getItem(userThemeKey);
            let version = -1;
            if (localCurrentTheme) {
                localCurrentTheme = JSON.parse(localCurrentTheme);
                version = localCurrentTheme.code + "_v_" + (localCurrentTheme.version || 0);
            }           

            //设置主题
            const setTheme = (currentThemeConfig, version) => {
                let currentThemeVersion = currentThemeConfig.code + "_v_" + (currentThemeConfig.version || 0);
                if (currentThemeVersion == version) {
                    return;
                }
                localStorage.setItem(userThemeKey, JSON.stringify(currentThemeConfig));
                top.location.reload();
            }
            // 获取配置的当前主题
            const currentThemeData = await Config.getOrgConfig("entry", "current-theme", undefined, userInfo.id);
            let currentThemeContent = currentThemeData?.[0];
            let currentThemeCode =  localStorage.getItem("mobile-local-currentTheme")||currentThemeContent?.content?.mobileCurrentTheme||"theme";

            //获取配置的主题
            const themeConfigData = await Config.getConfigByPage(page, "entry", "theme-config");
            let themes = themeConfigData?.[0]?.content?.themes;
            //在配置的主题中查找
            let currentThemeConfig = themes.filter(item => item.code == currentThemeCode);
            if (currentThemeConfig && currentThemeConfig.length > 0) {
                let theme = currentThemeConfig[0];
                theme.version=themeConfigData?.[0]?.version||0;
                if (theme.token) theme.token = JSON.parse(theme.token);
                if (theme.components) theme.components = JSON.parse(theme.components);
                if (theme.style) theme.style = JSON.parse(theme.style);
                currentThemeConfig.code = currentThemeCode;
                setTheme(theme, version);
            }else{
                //在configContext.js中查找
                currentThemeConfig = page.configContext[currentThemeCode];
                if (currentThemeConfig&&!currentThemeConfig.version) {
                    currentThemeConfig.code = currentThemeCode;
                    setTheme(currentThemeConfig, version);                
                }else{
                    setTheme({code:"theme",version:0}, version);
                }
            }
            history.router.configContext=page.configContext;
        } catch (error) {
            console.warn(error)
        }
    }
}
