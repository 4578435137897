module.exports = [
  {
    path: '/mobile/work/:pagetime?',
    pattern: /^\/mobile\/work(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/mobile/work.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/mobile/work.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/mobile/index/:pagetime?',
    pattern: /^\/mobile\/index(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/mobile/index.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/mobile/index.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/comp/wfmui/components/wfmui/mxDialog/batchTask/:pagetime?',
    pattern: /^\/comp\/wfmui\/components\/wfmui\/mxDialog\/batchTask(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/comp/wfmui/components/wfmui/mxDialog/batchTask.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/comp/wfmui/components/wfmui/mxDialog/batchTask.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/comp/wfmui/components/wfmui/mxDialog/record/:pagetime?',
    pattern: /^\/comp\/wfmui\/components\/wfmui\/mxDialog\/record(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/comp/wfmui/components/wfmui/mxDialog/record.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/comp/wfmui/components/wfmui/mxDialog/record.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/wxsys/comps/user/userDialog/user/:pagetime?',
    pattern: /^\/wxsys\/comps\/user\/userDialog\/user(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/wxsys/comps/user/userDialog/user.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/wxsys/comps/user/userDialog/user.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/comp/wfmui/components/wfmui/mxDialog/dialog/:pagetime?',
    pattern: /^\/comp\/wfmui\/components\/wfmui\/mxDialog\/dialog(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/comp/wfmui/components/wfmui/mxDialog/dialog.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/comp/wfmui/components/wfmui/mxDialog/dialog.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/mobile/dialog/taskList/:pagetime?',
    pattern: /^\/mobile\/dialog\/taskList(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/mobile/dialog/taskList.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/mobile/dialog/taskList.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/comp/vantui/components/Signature/dialog/signDialog/:pagetime?',
    pattern: /^\/comp\/vantui\/components\/Signature\/dialog\/signDialog(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/comp/vantui/components/Signature/dialog/signDialog.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/comp/vantui/components/Signature/dialog/signDialog.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/mobile/dialog/worker/:pagetime?',
    pattern: /^\/mobile\/dialog\/worker(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/mobile/dialog/worker.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/mobile/dialog/worker.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/mobile/user/forgetPassword/:pagetime?',
    pattern: /^\/mobile\/user\/forgetPassword(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/mobile/user/forgetPassword.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/mobile/user/forgetPassword.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/wxsys/comps/user/userDialog/authorize/:pagetime?',
    pattern: /^\/wxsys\/comps\/user\/userDialog\/authorize(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/wxsys/comps/user/userDialog/authorize.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/wxsys/comps/user/userDialog/authorize.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/mobile/task/:pagetime?',
    pattern: /^\/mobile\/task(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/mobile/task.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/mobile/task.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/comp/wxxcx_login/components/wxxcx_login/dialog/getPhoneNumber/:pagetime?',
    pattern: /^\/comp\/wxxcx_login\/components\/wxxcx_login\/dialog\/getPhoneNumber(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/comp/wxxcx_login/components/wxxcx_login/dialog/getPhoneNumber.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/comp/wxxcx_login/components/wxxcx_login/dialog/getPhoneNumber.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/mobile/dialog/selectIcon/:pagetime?',
    pattern: /^\/mobile\/dialog\/selectIcon(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/mobile/dialog/selectIcon.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/mobile/dialog/selectIcon.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/wxsys/comps/user/userDialog/changePassword/:pagetime?',
    pattern: /^\/wxsys\/comps\/user\/userDialog\/changePassword(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/wxsys/comps/user/userDialog/changePassword.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/wxsys/comps/user/userDialog/changePassword.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/comp/wfmui/components/wfmui/mxDialog/chart/:pagetime?',
    pattern: /^\/comp\/wfmui\/components\/wfmui\/mxDialog\/chart(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/comp/wfmui/components/wfmui/mxDialog/chart.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/comp/wfmui/components/wfmui/mxDialog/chart.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/mobile/dialog/icon/:pagetime?',
    pattern: /^\/mobile\/dialog\/icon(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/mobile/dialog/icon.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/mobile/dialog/icon.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/mobile/user/userInfo/:pagetime?',
    pattern: /^\/mobile\/user\/userInfo(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/mobile/user/userInfo.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/mobile/user/userInfo.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/mobile/org/:pagetime?',
    pattern: /^\/mobile\/org(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/mobile/org.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/mobile/org.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/mobile/user/login/:pagetime?',
    pattern: /^\/mobile\/user\/login(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/mobile/user/login.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/mobile/user/login.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/wxsys/comps/user/userDialog/bindphone/:pagetime?',
    pattern: /^\/wxsys\/comps\/user\/userDialog\/bindphone(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/wxsys/comps/user/userDialog/bindphone.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/wxsys/comps/user/userDialog/bindphone.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/mobile/mine/:pagetime?',
    pattern: /^\/mobile\/mine(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/mobile/mine.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/mobile/mine.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/mobile/infoPublish/infoPub/:pagetime?',
    pattern: /^\/mobile\/infoPublish\/infoPub(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/mobile/infoPublish/infoPub.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/mobile/infoPublish/infoPub.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/mobile/user/register/:pagetime?',
    pattern: /^\/mobile\/user\/register(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/mobile/user/register.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/mobile/user/register.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/wxsys/comps/user/userDialog/register/:pagetime?',
    pattern: /^\/wxsys\/comps\/user\/userDialog\/register(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/wxsys/comps/user/userDialog/register.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/wxsys/comps/user/userDialog/register.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/comp/entorgsm/components/orgSelect/dialog/orgDialog/:pagetime?',
    pattern: /^\/comp\/entorgsm\/components\/orgSelect\/dialog\/orgDialog(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/comp/entorgsm/components/orgSelect/dialog/orgDialog.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/comp/entorgsm/components/orgSelect/dialog/orgDialog.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/mobile/dialog/loginConfirm/:pagetime?',
    pattern: /^\/mobile\/dialog\/loginConfirm(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/mobile/dialog/loginConfirm.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/mobile/dialog/loginConfirm.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/wxsys/comps/user/userDialog/twofactorlogin/:pagetime?',
    pattern: /^\/wxsys\/comps\/user\/userDialog\/twofactorlogin(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/wxsys/comps/user/userDialog/twofactorlogin.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/wxsys/comps/user/userDialog/twofactorlogin.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/wxsys/comps/user/userDialog/login/:pagetime?',
    pattern: /^\/wxsys\/comps\/user\/userDialog\/login(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/wxsys/comps/user/userDialog/login.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/wxsys/comps/user/userDialog/login.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/mobile/infoPublish/infoView/:pagetime?',
    pattern: /^\/mobile\/infoPublish\/infoView(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/mobile/infoPublish/infoView.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/mobile/infoPublish/infoView.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/mobile/dialog/register/:pagetime?',
    pattern: /^\/mobile\/dialog\/register(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/mobile/dialog/register.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/mobile/dialog/register.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/mobile/user/changePassword/:pagetime?',
    pattern: /^\/mobile\/user\/changePassword(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/mobile/user/changePassword.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/mobile/user/changePassword.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/comp/vantui/components/OrgSelect/dialog/orgSelectDialog/:pagetime?',
    pattern: /^\/comp\/vantui\/components\/OrgSelect\/dialog\/orgSelectDialog(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/comp/vantui/components/OrgSelect/dialog/orgSelectDialog.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/comp/vantui/components/OrgSelect/dialog/orgSelectDialog.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/wxsys/comps/user/userDialog/forgetPassword/:pagetime?',
    pattern: /^\/wxsys\/comps\/user\/userDialog\/forgetPassword(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/wxsys/comps/user/userDialog/forgetPassword.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/wxsys/comps/user/userDialog/forgetPassword.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/mobile/infoPublish/infoDetail/:pagetime?',
    pattern: /^\/mobile\/infoPublish\/infoDetail(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/mobile/infoPublish/infoDetail.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/mobile/infoPublish/infoDetail.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
];