import URI from 'urijs';
let searchInfo = new URI(location.href).query(true);
let isDebug = searchInfo.isDebug;
let isMicroService = searchInfo.isMicroService;

if (isDebug === "false") {
  window.isDebug = false;
} else if (isDebug === "true") {
  window.isDebug = true;
}

if (isMicroService === "false") {
  window.isMicroService = false;
} else if (isDebug === "true") {
  window.isMicroService = true;
}
import './template/css/app.css';
import {createPath, parsePath} from 'history'
import './template/css/default.css';
import './template/css/index.css';
import './core/passiveEvent';
import PullDownRefresh from './components/pullDownRefresh/pullDownRefresh';
import './core/disableChromePullToRefresh';
import './core/keyboard';
import 'url-polyfill';
// pwa support
import './core/error';
import * as offlineRuntime from 'offline-plugin/runtime';
import React, {cloneElement} from 'react';
import ReactDOM from 'react-dom';
import FastClick from 'fastclick';
import router from './core/router';
import history from './core/appHistory';
import './core/api/wxApi';
import './pages/index';
import tabbar from './core/tabbar';
import header from './core/header';
import uniqueId from 'lodash/uniqueId';
import last from 'lodash/last';
import {animationEnd, isX5App, attachDoubleClickExitApp, addOpenerRoute} from 'core/utils';
import pageLoader from './core/framework/loader';
import 'inobounce';
import detector from "detector";
import {_formatUrl} from "./core/api/ui/navigate";

let routes = require('./routes.js');
window.routes = routes;

// emulate touch in desktop mode
if (typeof window.ontouchstart === 'undefined') {
  require("./core/touch-emulate/touch-emulate");
}


header.tabbar = tabbar;
let mainPagePath = routes[0] ? routes[0].path.replace('/:pagetime?', ''):"/mobile/main";
let rootContainer = document.querySelector('#container');
const currentPages = router.currentPages || [];
router.currentPages = currentPages;
let pageInstance = 'page';


window.getCurrentReactPages = function () {
  let pages = [];
  currentPages.forEach((page) => {
    pages.push(page);
  });
  return pages;
};

window.getCurrentReactPage = function () {
  return _.last(currentPages);
};

window.getCurrentPages = function () {
  let pages = [];
  currentPages.forEach((page) => {
    pages.push(page.wxPageDeclaration);
  });
  return pages;
};

function renderComponent(component, target, id, action, path) {

  if (tabbar.isTabPage(path)) {
    let oldPageContainer = target.parentElement.querySelector('.current_page');
    if (oldPageContainer != null && oldPageContainer != target) {
      oldPageContainer.classList.remove('current_page');
    }
    target.classList.add('current_page');
    renderTabPage(component, target, id, action, path);
  } else {
    /*
    if (action == "PUSH" && document.querySelectorAll("#container>.page_container").length > 1) {
      target.classList.add("page-from-right-to-center");
      target.parentElement.classList.add("page-animationing");
      animationEnd(target, function () {
        target.classList.add('current_page');
        target.classList.remove("page-from-right-to-center");
        target.parentElement.classList.remove("page-animationing");
      });
    } else {
      target.classList.add('current_page');
    }
    */
    renderPage(component, target, id, action, path);
  }
}

function renderTabPage(component, target, id, action, path) {
  let currentPage = last(currentPages);
  let page;


  if (currentPage && tabbar.isTabPage(currentPage.wxPageDeclaration.route)) {
    if (currentPage.onHide && (typeof currentPage.onHide == "function")) {
      currentPage.onHide();
    }
  }
  let isNewTab = false;
  if (target[pageInstance]) {
    page = target[pageInstance];
  } else {
    isNewTab = true;
    //slm
    page = ReactDOM.render(React.createElement(
      PullDownRefresh, {pageId:id,renderRoot:target}, component
    ), target).page;
    page.renderRoot = target;
    target.setAttribute('route',page.route);

    // 页面page的background-color配置在这里处理
    let backgroundColor = page.getPageConfig('backgroundColor');
    target.style.cssText = `background-color: ${backgroundColor};`;
    target[pageInstance] = page;
  }
  page.pageId = id;
  // clean array
  currentPages.splice(0);
  currentPages.push(page);

  // TODO:提前获取配置信息
  header.show(page.getPageConfig());
  tabbar.show(path, page.getPageConfig());
  /*if (isNewTab && page.onLoad && (typeof page.onLoad == "function")) {
    page.onLoad();
  }*/
  if (page.onShow && (typeof page.onShow == "function")) {
    //swtich tab 场景
    if (action === "PUSH") {
      page.onShow();
    }
    //pop 或者replace 场景 走老页面的anotationend事件
  }
  if (isNewTab && page.onReady && (typeof page.onReady == "function")) {
    page.onReady();
  }
}


function renderPage(component, target, id, action, path) {
  let page;
  // 兼容swatchTab情况
  if (action === "POP") {
    page = target[pageInstance];
  } else {
    page = ReactDOM.render(React.createElement(
      PullDownRefresh, {pageId:id,renderRoot:target}, component
    ), target).page;
    page.renderRoot = target;
    target.setAttribute('route',page.route);
    // 页面page的background-color配置在这里处理
    let backgroundColor = page.getPageConfig('backgroundColor');
    target.style.cssText = `background-color: ${backgroundColor};`;
    target[pageInstance] = page;
  }

  page.pageId = id;
  if (action === "PUSH") {
    let currentPage = last(currentPages);

    if (currentPage) {
      let pid = currentPage.pageId;
      if (currentPage.onHide && (typeof currentPage.onHide == "function")) {
        currentPage.onHide();
      }
      page.pagePid = pid;
    } else {
      //首页提前渲染
      target.classList.add('current_page');
    }
    currentPages.push(page);


    /*if (page.onLoad && (typeof page.onLoad == "function")) {
      page.onLoad();
    }*/
    if (page.onShow && (typeof page.onShow == "function")) {
      page.onShow();
    }

    animationEnd(target, function () {
      //console.log("animi end");
      // TODO:提前获取配置信息
      header.show(page.getPageConfig());
      let oldPageContainer = target.parentElement.querySelector('.current_page');
      if (oldPageContainer != null && oldPageContainer != target) {
        oldPageContainer.classList.remove('current_page');
      }
      target.classList.add('current_page');
      target.classList.remove("page-from-right-to-center");

      target.parentElement.classList.remove("page-animationing");
      if (page.onReady && (typeof page.onReady == "function")) {
        page.onReady();
      }
      tabbar.show(path, page.getPageConfig());
    });
    target.classList.add("page-from-right-to-center");
    target.parentElement.classList.add("page-animationing");
  } else if (action === "REPLACE") {
    let currentPage = last(currentPages);
    page.pagePid = currentPage.pagePid;
    currentPages.splice(-1);
    currentPages.push(page);
    // TODO:提前获取配置信息
    header.show(page.getPageConfig());
    tabbar.show(path, page.getPageConfig());
    target.classList.add('current_page');
  } else if (action === "POP") {
    currentPages.splice(-1);
    // currentPages = dropRight(currentPages);
    let currentPage = last(currentPages);
    if (currentPage) {
      page.pagePid = currentPage.pagePid;
      currentPages.splice(-1);
      // currentPages = dropRight(currentPages);
    }
    currentPages.push(page);
    // TODO:提前获取配置信息
    header.show(page.getPageConfig());
    tabbar.show(page.props.location.pathname, page.getPageConfig());
    target.classList.add('current_page');
  }

}

function createPageContainer(id, src, hidden, external = false) {
  let el = document.querySelector(`#${id}`);
  if (el) {
    return el;
  }
  if (external) {
    el = document.createElement('iframe');
    el.setAttribute('src', src);
    el.setAttribute('frameborder', "0");
  } else {
    el = document.createElement('div');
  }
  el.classList.add('page_container');
  el.classList.add('WxPage');
  el.setAttribute('id', id);
  el.setAttribute('width', hidden ? "0" : "100%");
  el.setAttribute('height', hidden ? "0" : "100%");
  if (hidden) {
    el.setAttribute('style', 'width:0;height:0;border:0; display:none;')
  }
  rootContainer.appendChild(el);
  return el
}

// Find and render a web page matching the current URL path,
// if such page is not found then render an error page (see routes.json,
// core/router.js)
// listen tabbar route
function render(location, action = "PUSH") {
  let path = location.pathname.replace(/\/[0-9]+$/, '');
  let oldPageContainer = document.querySelector('#container>.current_page');
  let id;
  let pid;
  let newPageContainer;
  return router.resolve(location).then((component) => {
    let currentPage = last(currentPages);
    if (!currentPage) {
      action = "PUSH";
    }
    //tabbar.show(path);
    if (action == "POP") {
      // 获取要返回的节点
      let pid = currentPage.pagePid;
      let pagePath = history.location.pathname.replace(/\/[0-9]+$/, '');
      let pages = document.querySelectorAll(`#container>[data-page-path='${pagePath}']`);
      // 兼容手动修改hash之后 historylib认为pop的情况
      if (pages.length == 0) {
        action = "PUSH";
      } else {
        newPageContainer = last(pages);
        if (!newPageContainer) {
          newPageContainer = document.querySelector(`#container>.main_page`);
        }
      }
    }

    if (action == "POP" || action == "REPLACE") {
      // 销毁react节点 销毁current_page
      if (oldPageContainer && oldPageContainer != newPageContainer) {
        oldPageContainer.parentElement.classList.add("page-animationing");
        oldPageContainer.classList.add("page-from-center-to-right");
        animationEnd(oldPageContainer, function () {
          if (oldPageContainer.parentElement) {
            oldPageContainer.parentElement.classList.remove("page-animationing");
          }
          ReactDOM.unmountComponentAtNode(oldPageContainer);
          oldPageContainer.remove();
          let page = newPageContainer[pageInstance];
          if (action == "REPLACE") {
            /*if (page.onLoad && (typeof page.onLoad == "function")) {
              page.onLoad();
            }*/
            if (page.onShow && (typeof page.onShow == "function")) {
              page.onShow();
            }
            if (page.onReady && (typeof page.onReady == "function")) {
              page.onReady();
            }
          } else if (action == "POP") {
            if (page.onShow && (typeof page.onShow == "function")) {
              page.onShow();
            }
          }
        });
      }
    } else if (action == "PUSH" && oldPageContainer) {

      // 兼容swatchTab的情况
      if (tabbar.isTabPage(path)) {
        let tabPagePath = tabbar.getTabPagePath(path);
        let tabPage = document.querySelector(`#container>[data-page-path='${path}']`);
        if (tabPage) {
          newPageContainer = tabPage;
          // 销毁react节点 销毁current_page
          let oldPagePath = oldPageContainer.getAttribute("data-page-path");
          if (oldPageContainer && !tabbar.isTabPage(oldPagePath)) {
            ReactDOM.unmountComponentAtNode(oldPageContainer);
            oldPageContainer.remove();
          }
        }
      }
    }

    /*
	 * if(document.querySelectorAll('#container>div').length > 5){ throw new
	 * Error("打开页面层次太深，超过最大深度：5"); }
	 */
    // 维护不同情况的id 和pid
    if (!newPageContainer) {
      id = uniqueId('page_' + path.replace(/\//g, "_").replace(/:/g, "_").replace(/\./g, "_"));
      newPageContainer = createPageContainer(id);
    } else {
      id = newPageContainer.getAttribute('id');
    }
    if (path === mainPagePath) {
      newPageContainer.classList.add('main_page');
    }
    if (tabbar.isTabPage(path)) {
      newPageContainer.classList.add('tab_page');
    }
    newPageContainer.setAttribute("data-page-path", path);
    return component;
  })
    .then((component) => renderComponent(component, newPageContainer, id, action, path));
}

wx.uixPageRender = function (params, target) {
  return pageLoader.menuResolved.then(()=> {
    //底层默认加openerRouter方便和其他框架整合
    addOpenerRoute(params,"$router");

    let _url = _formatUrl(params);
    if (wx && wx.ServiceUtil && wx.ServiceUtil.addExecutor) _url = wx.ServiceUtil.addExecutor(_url);

    let pageInfo = parsePath(_url);
    return router.resolve(pageInfo).then((component) => {
    	component = cloneElement(component, {
          ownerPage: params.currentPage,
  		  	ownerModel:params.currentModel,
          renderRoot:target
        });

      let page = ReactDOM.render(component, target);
      page.renderRoot = target;
      target.setAttribute('route',page.route);


      page.pageId = target.getAttribute('id');
      if(!page.pageId){
        let id = uniqueId('page_' + _url.replace(/\//g, "_"));
        target.setAttribute('id',id);
        page.pageId = id;
      }

      target.page = page;
      let path = pageInfo.pathname;
      path = path.replace(/\/[0-9]+$/, '');
      page.wxPageDeclaration.route = path.replace(/^\//, "");

      /*if (page.onLoad && (typeof page.onLoad == "function")) {
        page.onLoad(page.props.options);
      }*/
      if (page.onShow && (typeof page.onShow == "function")) {
        page.onShow();
      }

      if (page.onReady && (typeof page.onReady == "function")) {
        page.onReady();
      }
      return page;
    });
  });
};
wx.render = render;

function init() {
  offlineRuntime.install();
  if(window.isMicroService){
    //微服务模式，默认首页必须是门户的默认页面，登录后再跳转到期望跳转的页面
    let initUrl = location.hash.substring(1);
    window.microService.initUrl = initUrl;
    window.microService.mainPagePath = mainPagePath;
    history.replace(initUrl || mainPagePath);
  }else{
    if (history.location.pathname === "/") {
      //兼容参数中main传递首页地址的情况
      let _mainPagePath = new URI(location.href).query(true).main;
      if(_mainPagePath){
        mainPagePath = _mainPagePath;
      }
      history.replace(mainPagePath);
    }
  }

  tabbar.reset();
  if (isX5App) {
    attachDoubleClickExitApp(function(){
      let currentPage = last(currentPages);
      if (currentPage && tabbar.isTabPage("/" + currentPage.wxPageDeclaration.route)) {
          return true;
      }else if(currentPage && currentPage.wxPageDeclaration.route.indexOf("/userDialog/login")!=-1){
          return true;
      }
      return false;
    });

    document.addEventListener('deviceready', function () {
      //iOS开启侧滑返回
      if(detector.os.name=="ios"){

        window.WkWebView.allowsBackForwardNavigationGestures(true);
      }
      render(history.location);
    }, false);
  } else {
    render(history.location);
  }
  if(detector.os.name=="ios"){
    document.body.classList.add('ios-wkwebview');
  }




  history.listen(function ({location, action}) {
    render(location, action);
  });
// Eliminates the 300ms delay between a physical tap
// and the firing of a click event on mobile browsers
// https://github.com/ftlabs/fastclick
  if (navigator.platform != "Win32") {
    let deviceIsWindowsPhone = navigator.userAgent.indexOf("Windows Phone") >= 0;
    let deviceIsIOS = /iP(ad|hone|od)/.test(navigator.userAgent) && !deviceIsWindowsPhone;
    FastClick.prototype.focus = function(targetElement) {
      let length;
      // Issue #160: on iOS 7, some input elements (e.g. date datetime month) throw a vague TypeError on setSelectionRange. These elements don't have an integer value for the selectionStart and selectionEnd properties, but unfortunately that can't be used for detection because accessing the properties also throws a TypeError. Just check the type instead. Filed as Apple bug #15122724.
      if (deviceIsIOS && targetElement.setSelectionRange && targetElement.type.indexOf('date') !== 0 && targetElement.type !== 'time' && targetElement.type !== 'month' && targetElement.type !== 'email') {
        length = targetElement.value.length;
        targetElement.setSelectionRange(length, length);

        //江苏规划建议加focus逻辑 修复input弹出问题
        targetElement.focus();
      } else {
        targetElement.focus();
      }
    };

    FastClick.attach(document.querySelector('#container'));
    FastClick.attach(document.querySelector('#header'));
    FastClick.attach(document.querySelector('#tabbar'));
  }

  window.addEventListener('message', function (event) {
    if (event.data && event.data.type == "hash") {
      let hash = event.data.hash;
      if (hash) {
        window.location.hash = hash;
      }
    }
  });
  window.inited = true;
}


if (!window.inited) {
  init();
}



//支持微服务场景下的移动门户调试运行
if(window.isDebug && window.isMicroService){
  let mobilePageHmrTimer = setInterval(function(){

    if(window.debugServiceName){
        clearInterval(mobilePageHmrTimer);
        let syncClient = document.createElement("script");
        syncClient.setAttribute("src", "/" + window.debugServiceName + "/mobileapp/browser-sync-client.js");
        document.body.appendChild(syncClient);

        syncClient.addEventListener("load", function(){
          window.___browserSync___.socketConfig.path = "/" +  window.___browserSync___.options.logPrefix + window.___browserSync___.socketConfig.path;
          window.___browserSync___.socket.on("ui:page:change",function(){
            if(window.debugServiceName == location.hash.split("/")[1]){
              let vcSwitch = document.querySelector('.vc-switch');
              vcSwitch.classList.add("vc-hmr");
              vcSwitch.innerText = "热更新成功";
              setTimeout(function (){
                vcSwitch.classList.remove("vc-hmr");
                vcSwitch.innerText = "...";
              },1000);
              wx.redirectTo({url:location.hash.substring(1),prependServicePath:false});
            }
          });
        }, false);
    };
  },300);

}
