import {initenv} from './wxsys/lib/base/initenv';
 wx.App = {baseUrl: '', resPath: ''};
initenv();

import UserImplement from '$UI/wxsys/comps/uixContainer/UserImplement';
import history from "core/appHistory";
import ActionUtil from "$UI/mobile/js/actionUtil";
import ConfigUtil from "$UI/mobile/js/configUtil";
import store from 'store';

App({
    async onLaunch({page}) {  
        //设置上一次主题
        ActionUtil.setCurrentThemeByLocal(page);
        //iframe页面方法兼容处理
        try{
            ActionUtil.getMessage(page);
        }catch(e){
            console.error("iframe页面方法兼容处理异常",e);
        }
        //跳转url        
        let sourcePath = "";

        //获取page参数进行跳转
        const {page:pageUrl}=this.searches||{};   
        try {
            let userInfo = await UserImplement.checkLogin(page);
            //设置语言资源
            await ActionUtil.setCurrentLanguage(page,userInfo);            
            //初始化岗位信息，默认主岗
            let [orgData, menus,portalPublicConfig,portalConfig] = await Promise.all([
                ActionUtil.getOrgData(userInfo),
                ActionUtil.getCurrentMenus(page, userInfo),                 
                ConfigUtil.getPortalPublicConfig(page),
                ConfigUtil.getPortalConfig(page)
            ]);     
            let [defaultMenu,currentTheme] = await Promise.all([        
                //获取默认菜单       
                ActionUtil.getDefaultMenu(page,false,portalConfig),    
                //动态获取主题信息进行设置 
                ActionUtil.setCurrentTheme(page,userInfo)
            ]);     
            //通过菜单获取主要的跳转url
            sourcePath=defaultMenu?.url||window.microService.mainPagePath;   
            
            //同步皮肤主题色到整个移动页面上
            let {configContext}=page;
            let primaryColor=configContext[configContext.currentTheme]?.token?.primaryColor;
            if(primaryColor){                
                document.documentElement.style.setProperty('--primary-color', primaryColor);  
            }

            //page参数跳转
            if(pageUrl){                 
                page.navigateTo({ url:pageUrl, params: { executor: ""} });                        
                return;
            }

            history.router.configContext=configContext;        
            //登录成功后初始页面为空的情况跳转到指定默认页面          
            let {initUrl}=window.microService;       
            const getOriginUrl=(pageObj,url=pageObj.pagePath)=>{
                const prefixPath=`/${pageObj.serviceName}/${pageObj.contextPath}`;
                if(url.startsWith(prefixPath)){
                    return url.substr(prefixPath.length);
                }
                return url;
            }           
            if(!initUrl&&(sourcePath!=page.pagePath)&&(sourcePath!=getOriginUrl(page))){  
                store.set("defaultUrl",sourcePath);                                        
                history.replace(sourcePath);
            }         
        } catch (error) {
            //设置多语言            
            await ActionUtil.setCurrentLanguage(page);

            sourcePath = pageUrl||history.getLocation().pathname;
            let loginUrl = ActionUtil.initStateConfig.loginPage;            
            if (sourcePath != loginUrl) {
                history.replace(loginUrl+"?redirect="+sourcePath);
            }
        }
    }
})
import '../core/framework/app';
import AppConfig from './app.json';
getApp().setAppConfig(AppConfig);
