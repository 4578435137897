import Config from "../../wxsys/lib/base/config";
export default {
    configList:{
        portalConfig:"portal-mobile-config",
        loginConfig:"login-mobile-config",
        portalPublicConfig:"portal-public-config",
        iconConfig:"icon"
    },
    async getConfig(page,configStr,serviceName="entry"){  
        return await Config.getConfigByPage(page,serviceName, configStr);
    },
    getPortalConfig(page){
        return this.getConfig(page,this.configList.portalConfig);
    },
    getLoginConfig(page){
        return this.getConfig(page,this.configList.loginConfig);
    },
    getIconConfig(page){
        return this.getConfig(page,this.configList.iconConfig);
    },
    async getPortalPublicConfig(page){
        window.portalPublicConfig=await this.getConfig(page,this.configList.portalPublicConfig);
        return window.portalPublicConfig;
    }
}
